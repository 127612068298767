const config = {
  RANKTIVITY_HOST: `rktvty-marketing-staging.web.app`,
  DEFAULT_PLAN: `Plus`,
  ASSETS: `http://localhost:9199/ranktivity-staging-f1c26.appspot.com`,
  // imgix: "http://127.0.0.1:9199/ranktivity-staging-f1c26.appspot.com/",
  imgix: "https://ranktivity-staging.imgix.net",
  production: false,
  mailService: `mailgun`,
  functionUrl: `${process.env.NEXT_PUBLIC_FUNCTION_URL}`,
  firebase: {
    addMessage: `/addMessage`,
    testGetTechAudits: `/testGetTechAudits`,
    googleAnalyticsScheduled: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/googleAnalyticsScheduled`,
    autoDiff: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/autoDiffImagesPath/auto-diff/`,
    imageDiff: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/autoDiffImagesPath/image-diff`,
    asanaGetProjects: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/asanaGetProjects`,
    asanaCreateTask: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/asanaCreateTask`,
    checkLimits: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/checkLimits`,
    createTechAudit: `/createTechAudit`,
    keywords: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/keywords`,
    getKeywords: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getKeywords`,
    getWebsites: `/getWebsites`,
    getWebsite: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getWebsite`,
    getWebsiteKeywords: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getWebsiteKeywords`,
    getWebsiteData: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getWebsiteData`,
    getUserData: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getUserData`,
    getSeoScans: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getSeoScans`,
    getSocialStats: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getSocialStats-getSocialStats`,
    getTechAudits: `/getTechAudits`,
    getTechAuditsByOrg: `/getTechAuditsByOrg`,
    getFavicon: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/saveFavicon`,
    createStripeUser: `${process.env.APP_API_HOST}/createStripeUser`,
    handleInvites: `/handleInvites`,
    acceptInvite: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/acceptInvite`,
    resendInvite: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/resendInvite`,
    gaAuth: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/gaAuth`,
    gaUserInfo: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/gaUserInfo`,
    gaGetProperties: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/gaGetProperties`,
    gaGetViews: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/gaGetViews`,
    asanaAuth: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/asanaAuth`,
    shopifyAuth: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/shopifyAuth`,
    screenShot: `/screenShot`,
    uploadFile: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/uploadFile`,
    sendWelcomeEmail: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/sendWelcomeEmail`,
    websiteUpdate: `http://localhost:5001/ranktivity-websites-dev-update`,
    createSeoScan: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/createSeoScan`,
    storage: `http://localhost:9199`,
    logEvent: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/dataDogEvent`,
    handleNotification: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/handleNotification`,
    getTechAuditByAuditId: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getTechAuditByAuditId`,
    stripe: `${process.env.APP_API_HOST}/stripe`,
    foo: `bar`,
  },
};

export default config;
