const config = {
  RANKTIVITY_HOST: 'rktvty-marketing-staging.web.app',
  DEFAULT_PLAN: 'Plus',
  ASSETS: 'https://rktvty-assets.imgix.net',
  imgix: "https://rktvty-assets.imgix.net",
  production: true,
  mailService: 'mailgun',
  functionUrl: `${process.env.NEXT_PUBLIC_FUNCTION_URL}`,
  firebase: {
    autoDiff: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/autoDiffImagesPath/auto-diff/`,
    imageDiff: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/autoDiffImagesPath/image-diff`,
    asanaGetProjects: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/asanaGetProjects`,
    asanaCreateTask: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/asanaCreateTask`,
    checkLimits: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/checkLimits`,
    createTechAudit: `/createTechAudit`,
    keywords: `${process.env.NEXT_PUBLIC_FUNCTION_URL}//keywords`,
    getWebsites: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getWebsites`,
    getWebsite: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getWebsite`,
    getWebsiteKeywords: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getWebsiteKeywords`,
    getKeywords: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getKeywords`,
    getWebsiteData: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getWebsiteData`,
    getUserData: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getUserData`,
    getSeoScans: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getSeoScans`,
    getSocialStats: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getSocialStats-getSocialStats`,
    getTechAudits: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getTechAudits`,
    getTechAuditsByOrg: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getTechAuditsByOrg`,
    getFavicon: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/saveFavicon`,
    createStripeUser: `${process.env.APP_API_HOST}/createStripeUser`,
    handleInvites: `/handleInvites`,
    acceptInvite: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/acceptInvite`,
    resendInvite: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/resendInvite`,
    gaAuth: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/gaAuth`,
    gaGetProperties: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/gaGetProperties`,
    gaGetViews: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/gaGetViews`,
    asanaAuth: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/asanaAuth`,
    shopifyAuth: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/shopifyAuth`,
    screenShot: `/screenShot`,
    uploadFile: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/uploadFile`,
    sendWelcomeEmail: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/sendWelcomeEmail`,
    websiteUpdate: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/ranktivity-websites-dev-update`,
    storage: 'https://storage.googleapis.com/ranktivity-prod-45a98.appspot.com',
    logEvent: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/dataDogEvent`,
    getTechAuditByAuditId: `${process.env.NEXT_PUBLIC_FUNCTION_URL}/getTechAuditByAuditId`,
  }
};

export default config;
