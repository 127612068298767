import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/24/outline";
import getAbsoluteURL from "../../utils/getAbsoluteURL";
import { useRouter } from "next/router";
import moment from "moment";
import Link from "next/link";
import { updateNotification } from "../../pages/api/notification";

const Notification = ({ oid }) => {
  const router = useRouter();
  const [notificationsdata, setnotificationsdata] = useState([]);

  const fetchData = useCallback(
    async (id) => {
      const endpoint = getAbsoluteURL("/api/notification");
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orgId: oid,
          notification: id,
        }),
      });
      const data = await response.json();
      if (!response.ok) {
        console.error(
          `Data fetching failed with status ${response.status
          }: ${JSON.stringify(data)}`
        );
        return null;
      }
      return data;
    },
    [router.route]
  );

  useEffect(() => {
    let isCancelled = false;
    const getnotificationData = async () => {
      const data = await fetchData();
      setnotificationsdata(data?.notification);
    };
    getnotificationData();
    return () => {
      // A quick but not ideal way to avoid state updates after unmount.
      // In your app, prefer aborting fetches:
      // https://developers.google.com/web/updates/2017/09/abortable-fetch
      isCancelled = true;
    };
  }, [fetchData, router.route]);

  const updatenotificationData = async (id) => {
    const data = await fetchData(id);
    console.log(`data from fetchData in Notifications`);
    console.log(data);
    setnotificationsdata(data?.notification);
  };

  const clearAll = async () => {
    const data = await fetchData("crealall");
    setnotificationsdata(data?.notification);
  };

  const clearAllNotification = async () => {
    const data = await fetchData("clearAllNotifications")
    setnotificationsdata(data.notification);
  }

  // console.log(notificationsdata);

  return (
    <>
      {/* Notifications dropdown */}
      <Menu as="div" className="relative ml-3">
        {({ close }) => (
          <>
            <div>
              <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
                <div className="relative">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                  <span
                    className={`absolute top-0 right-0 block h-2 w-2 -translate-y-1/2 translate-x-1/2 transform rounded-full ${notificationsdata?.filter((data) => data.dismissed === false)
                      .length > 0 && "bg-green-400"
                      } ring-2 ring-white`}
                  />
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute p-4 z-10 mt-3 w-96 right-0 text-gray-500 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" style={{ "minHeight": notificationsdata?.length > 0 ? 250 : 150 }}>
                <div className="flex mb-3 items-center">
                  <div className="ml-3  w-full text-xl font-medium text-black">
                    Notifications
                  </div>
                  {notificationsdata?.length > 0 && <div
                    className="inline-flex w-32 justify-end text-xs text-gray-500 cursor-pointer"
                    onClick={() => clearAll()}
                  >
                    Mark all as read
                  </div>}
                </div>
                <hr className="mb-2" />
                {notificationsdata?.length > 0 ?
                  <>
                    {notificationsdata?.slice(0, 4)?.map((notification, i) => {
                      return (
                        <Menu.Item key={notification.id}>
                          <div className={`flex pt-[15px] ${i > 0 ? 'border-t border-[#ebe7e7]' : ''}`}>
                            <div className="ml-3 text-xs font-normal w-full">
                              <span
                                className={`mb-1 text-sm font-medium ${notification.dismissed == true
                                  ? "text-gray-500"
                                  : "text-gray-900"
                                  }`}
                              >
                                {notification.title}
                              </span>
                              <div className="mb-2 text-xs font-normal">
                                {notification.description}
                              </div>
                              {notification.dismissed == false ? (
                                <button
                                  className="mb-2 text-xs font-normal text-black w-20 rounded-full p-1 bg-gray-100"
                                  onClick={() =>
                                    updatenotificationData(notification.id)
                                  }
                                >
                                  Dismiss
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="text-gray-400 text-xs justify-end inline-flex h-8 w-48">
                              {moment(notification.createdAt).fromNow()}
                            </div>
                          </div>
                        </Menu.Item>
                      );
                    })}
                  </> : <div
                    className="inline-flex w-full m-6 pr-6 justify-center items-center text-lg text-gray-500"
                  >
                    No Notifications
                  </div>
                }
                <div>
                  <hr className="mb-2" />
                  <div className="flex mb-3 w-full justify-items-center">
                    <Link href={`/org/${oid}`} shallow={true}>
                      <div
                        className="ml-3 justify-items-start w-full text-sm text-blue-600 cursor-pointer hover:underline"
                        onClick={close}
                      >
                        See all notifications
                      </div>
                    </Link>
                    {notificationsdata?.length > 0 && <div
                      className="w-20 justify-items-end text-sm text-blue-600 cursor-pointer hover:underline"
                      onClick={() => clearAllNotification()}
                    >
                      Clear all
                    </div>}
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};

export default Notification;
