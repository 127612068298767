import React, { useCallback, useEffect } from "react";
import { useUser, withUser, AuthAction } from "next-firebase-auth";
import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Disclosure } from "@headlessui/react";
import { getUserMemberships, orgHasFeature } from "../utils/orgDataHelper";
import RecentlyVisitedPages from "./Topbar/HistoryDropdown";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// firebase.firestore.setLogLevel("debug");

const firestore = firebase.firestore();

import {
  Bars3CenterLeftIcon,
  BellIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
  ChartBarIcon,
  ChevronUpIcon,
  KeyIcon,
  BellAlertIcon,
  CodeBracketIcon,
  BuildingOfficeIcon,
  PlusSmallIcon,
  RectangleGroupIcon,
  UserCircleIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";
import {
  BanknotesIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  BuildingOffice2Icon,
  CodeBracketSquareIcon,
  AdjustmentsHorizontalIcon,
  IdentificationIcon,
  PlusCircleIcon,
  PlusIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/20/solid";
import FullPageLoader from "./FullPageLoader";
import { useRouter } from "next/router";
import getAbsoluteURL from "../utils/getAbsoluteURL";
import Notification from "./Topbar/Notification";
import { useQuery } from "react-query";
import { orgAuth } from "../services/user";
import Link from "next/link";
import { checkBillingUnpaid } from "../services/website";
import NewDashboardDialog from "./modals/NewDashboardDialog";
import { getSubscription } from "../services/settings";
import cx from "classnames";
import OrgSwitcher from "./OrgSwitcher";
import { useOrg } from "./OrgContext";

function LayoutAppAuthed({ children }) {
  const { orgs, isLoading, activeOrg, refetch, isError, parentOrgId } =
    useOrg();
  const [collapsed, setCollapsed] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userHistoryEnabled, setOrgHasFeature] = useState(false);

  const data = activeOrg;

  const AuthUser = useUser(); // the user is guaranteed to be authenticated
  const router = useRouter();

  // const getUserOrg = async (uid) => {
  //   const userOrg = await getUserMemberships(uid);
  //   setOrgId(userOrg);
  //   return userOrg;
  // };

  // getUserOrg(AuthUser.id);
  const { oid, pid, pmid, taid } = router.query;

  const orgId = activeOrg?.orgId;

  // const {
  //   data: authorized,
  //   isLoading: authLoad,
  //   error: authError,
  // } = useQuery(["authorize", AuthUser.id, pid, pmid, taid], orgAuth);

  const {
    data: billingUnpaid,
    isLoading: billingStatusLoading,
    error: billingStatusError,
  } = useQuery({
    queryKey: ["BillingStatus", parentOrgId],
    queryFn: async () => {
      return checkBillingUnpaid(parentOrgId);
    },
    // The query will not execute until the userId exists
    enabled: !!data,
  });

  const [navMenu, setNavigation] = useState([]);
  const [adminMenuAdded, setAdminMenuAdded] = useState(false);

  //
  useEffect(() => {
    const fetchUserRole = async () => {
      if (AuthUser && !adminMenuAdded) {
        try {
          const userDocRef = firestore.collection("users").doc(AuthUser.id);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userRole = userDoc.data()?.role;

            // console.log(`userRole is `, userRole);
            if (userRole === "ranktivity_admin") {
              // const adminMenu = {
              //   id: "admin",
              //   name: "Admin",
              //   href: "/admin",
              //   icon: HomeIcon,
              //   current: router.asPath === `/admin`,
              // };

              // setNavigation((prevNavigation) => [adminMenu, ...prevNavigation]);
              setAdminMenuAdded(true);
              // localStorage.setItem("adminMenuAdded", true);
            }
          }
        } catch (error) {
          console.error("Error fetching user role:", error);
        }
      }
    };

    // Update navigation when orgId changes
    if (orgId !== null) {
      let navigation = [
        // Your existing navigation items...
        {
          name: "Dashboards",
          href: `/org/${orgId}/dashboards`,
          icon: RectangleGroupIcon,
          current: router.asPath.includes(`/org/${orgId}/dashboards`),
          id: "dashboars",
        },
        {
          name: "Websites",
          href: `/org/${orgId}/website`,
          icon: ChartBarIcon,
          current: router.asPath.includes(`/org/${orgId}/website`),
        },
        {
          name: "Keywords",
          href: `/org/${orgId}/keywords`,
          icon: KeyIcon,
          current: router.asPath === `/org/${orgId}/keywords`,
        },
        {
          name: "Competitors",
          href: `/org/${orgId}/competitors`,
          icon: BuildingOfficeIcon,
          current: router.asPath.includes(`/org/${orgId}/competitors`),
        },
        {
          name: "Alerts",
          href: `/org/${orgId}/alerts`,
          icon: BellAlertIcon,
          current: router.asPath === `/org/${orgId}/alerts`,
        },
        {
          name: "Page Monitor",
          href: `/org/${orgId}/page-monitor`,
          icon: DocumentChartBarIcon,
          current: router.asPath === `/org/${orgId}/page-monitor`,
        },
        {
          name: "Tech Audit",
          href: `/org/${orgId}/tech-audit`,
          icon: CodeBracketIcon,
          current: router.asPath === `/org/${orgId}/tech-audit`,
        },
        {
          name: "Ad Insights",
          href: `/org/${orgId}/ads`,
          icon: CodeBracketIcon,
          current: router.asPath === `/org/${orgId}/ads`,
        },
      ];
      setNavigation(navigation);
      fetchUserRole();
    }
  }, [AuthUser, router, adminMenuAdded, orgId, router.asPath]); // Only re-run effect if AuthUser, adminMenuAdded, or orgId changes
  //
  if (
    !billingStatusLoading &&
    billingUnpaid &&
    !window.location.pathname.split("/").includes("settings") &&
    AuthUser.id
  ) {
    router.push(`/org/${parentOrgId}/settings/plan-billing`);
  }

  // const { data: subscriptionData } = useQuery(
  //   ["subscription", AuthUser.id],
  //   async () => {
  //     const token = await AuthUser.getIdToken();
  //     return getSubscription(oid, AuthUser.id, token);
  //   },
  //   { retry: true, staleTime: 6000 }
  // );

  // if (authLoad || isLoading) return <FullPageLoader />;

  // debugger;
  // if (authError || orgError)
  //   debugger;
  //   return "An error has occurred: " + authError.message || orgError.message;

  // If no oid in URL redirect to their orgId overview page
  // if (!oid) {
  //   router.push(`/${orgId}/websites`);
  // }

  // If the oid param from URL is not the users orgId returned from react-query 404
  // if (orgId !== oid) {
  //   router.push(`/404`);
  // }

  // if (!authorized) {
  //   // debugger;
  //   // console.log(`before redirect ${firstOrgId}`)
  //   // TODO: show 404 page or what do other sites do?
  //   router.push(`/404`);
  // }

  const secondaryNavigation = [
    {
      name: "Help",
      target: "_blank",
      href: "https://ranktivity-help.freshdesk.com/support/tickets/new",
      icon: QuestionMarkCircleIcon,
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const signOut = () => {
    AuthUser.signOut();
  };

  const Icon = collapsed ? ChevronDoubleRightIcon : ChevronDoubleLeftIcon;

  const checkFeature = async (orgId) => {
    const hasUserHistory = await orgHasFeature(orgId, "userHistory");
    setOrgHasFeature(hasUserHistory);
  };

  checkFeature(orgId);

  return (
    <div className="min-h-full">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex h-full">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex h-full w-full max-w-xs flex-1 flex-col bg-gray-700 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex navbar-main-div-height flex-col bg-gray-700 scrollBox">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <Link href="/">
                      <img
                        className="h-8 w-auto"
                        src="/ranktivity-logo-light.svg"
                        alt="Rankivity"
                      />
                    </Link>
                  </div>
                  <div className="scrollbox-inner">
                    <nav
                      className="pt-5 h-full flex-shrink-0 divide-y divide-gray-800 overflow-y-auto"
                      aria-label="Sidebar"
                    >
                      <div className="space-y-1 px-2">
                        {navMenu.map((item) =>
                          !item.children ? (
                            <div key={item.name}>
                              <Link href={item.href} shallow={true}>
                                <a
                                  className={cx(
                                    item.current
                                      ? "bg-gray-800 text-white"
                                      : "text-gray-100 hover:text-white hover:bg-gray-600",
                                    "group flex items-center px-2 py-2 text-sm leading-6 font-medium",
                                    {
                                      "rounded-md": !collapsed,
                                      "rounded-full h-10 w-10": collapsed,
                                    }
                                  )}
                                >
                                  <item.icon
                                    className="mr-4 h-6 w-6 flex-shrink-0 text-gray-200"
                                    aria-hidden="true"
                                  />
                                  <span
                                    className={cx("truncate", {
                                      hidden: collapsed,
                                    })}
                                  >
                                    {item.name}
                                  </span>
                                </a>
                              </Link>
                            </div>
                          ) : (
                            <Disclosure
                              as="div"
                              key={item.name}
                              className="space-y-1"
                              defaultOpen={item.current}
                            >
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={cx(
                                      item.current
                                        ? "bg-gray-800 text-white"
                                        : "text-gray-100 hover:text-white hover:bg-gray-600",
                                      "group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium focus:outline-none ",
                                      {
                                        "rounded-md": !collapsed,
                                        "rounded-full w-10 h-10": collapsed,
                                      }
                                    )}
                                    onClick={() => {
                                      collapsed && setCollapsed(false);
                                    }}
                                  >
                                    <item.icon
                                      className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                    {!collapsed && (
                                      <span className="flex-1 truncate">
                                        {item.name}
                                      </span>
                                    )}
                                    <svg
                                      className={classNames(
                                        open
                                          ? "text-gray-400 rotate-90"
                                          : "text-gray-300",
                                        "ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400"
                                      )}
                                      viewBox="0 0 20 20"
                                      aria-hidden="true"
                                    >
                                      <path
                                        d="M6 6L14 10L6 14V6Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="space-y-1">
                                    {item.children.map((subItem) => (
                                      <Link
                                        key={subItem.name}
                                        href={subItem.href}
                                        shallow={true}
                                      >
                                        <Disclosure.Button
                                          key={subItem.name}
                                          as="a"
                                          href={subItem.href}
                                          className="group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-gray-100 hover:text-white hover:bg-gray-600"
                                        >
                                          {subItem.name}
                                        </Disclosure.Button>
                                      </Link>
                                    ))}
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          )
                        )}
                      </div>
                      <div className="mt-6 pt-6">
                        <div className="space-y-1 px-2">
                          {secondaryNavigation.map((item) => (
                            <Link
                              key={item.name}
                              href={item.href}
                              shallow={true}
                            >
                              <a
                                key={item.name}
                                className="group flex items-center rounded-md px-2 py-2 text-base font-medium text-gray-100 hover:bg-gray-600 hover:text-white"
                              >
                                <item.icon
                                  className="mr-4 h-6 w-6 text-gray-200"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
                <footer className="flex-shrink-0">
                  <Menu as="div" className="relative p-0 w-full">
                    <div>
                      <Menu.Button className="flex w-full items-center rounded-md py-2 pl-11 pr-9 text-sm font-medium text-gray-100 hover:text-white hover:bg-gray-600">
                        <span className="ml-3 text-sm font-medium text-white lg:block truncate">
                          <span className="sr-only">Open user menu for </span>
                          {AuthUser.email}
                        </span>
                        <ChevronUpIcon
                          className="ml-1 h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="top-2 transform -translate-y-full absolute right-0 w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {/* <Menu.Items className="bottom-full absolute top-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"> */}
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href={`/org/${orgId}/settings`}
                              shallow={true}
                            >
                              <a
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Profile
                              </a>
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href={`/org/${orgId}/settings`}
                              shallow={true}
                            >
                              <a
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Settings
                              </a>
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link href="#" shallow={true}>
                              <a
                                onClick={() => {
                                  signOut();
                                }}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Logout
                              </a>
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </footer>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Collapsible sidebar for desktop */}
      <div
        className={cx(
          "hidden lg:fixed lg:inset-y-0 lg:flex lg:flex-col md:translate-x-0 transition-all duration-300 ease-in-out h-full bg-gray-700",
          {
            "lg:w-64": !collapsed,
            "lg:w-24": collapsed,
          }
        )}
      >
        <div className="flex flex-grow flex-col navbar-main-div-height bg-gray-700 pt-5 pb-4 scrollbox">
          {/* logo and collapse button */}
          <div
            className={cx("flex flex-shrink-0 items-center transition-none", {
              "p-4 justify-between px-2": !collapsed,
              "py-4 justify-end pr-[0.5rem]": collapsed,
            })}
          >
            {!collapsed ? (
              <Link href="/">
                <img
                  className="h-8 w-auto"
                  src="/ranktivity-logo-light.svg"
                  alt="Ranktivity"
                />
              </Link>
            ) : (
              <Link href="/">
                <img
                  className="h-8 w-auto px-2"
                  src="/ranktivity_symbol@2x.png"
                  alt="Ranktivity"
                />
              </Link>
            )}
            <button
              className="grid place-content-center hover:bg-gray-800 w-10 h-10 rounded-full opacity-0 md:opacity-100"
              onClick={() => setCollapsed(!collapsed)}
            >
              <Icon className="w-6 h-6" fill="#fff" />
            </button>
          </div>

          <div className="">
            <nav
              className="mt-5 flex flex-1 flex-shrink-0 h-full flex-col divide-y divide-gray-800"
              aria-label="Sidebar"
            >
              <div className="space-y-1 px-2">
                {adminMenuAdded && (
                  <div key={"admin"}>
                    <Link href={"/admin"} shallow={true}>
                      <a
                        title={"admin"}
                        className={classNames(
                          router.asPath === `/admin`
                            ? "bg-gray-800 text-white"
                            : "text-gray-100 hover:text-white hover:bg-gray-600",
                          "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                        )}
                      >
                        <HomeIcon
                          className="mr-4 h-6 w-6 flex-shrink-0 text-gray-200"
                          aria-hidden="true"
                        />
                        <span
                          className={cx("truncate", {
                            hidden: collapsed,
                          })}
                        >
                          Admin
                        </span>
                      </a>
                    </Link>
                  </div>
                )}
                {/*  End admin menu  */}
                {navMenu.map((item) =>
                  !item.children ? (
                    <div key={item.name}>
                      <Link href={item.href} shallow={true}>
                        <a
                          title={item.title}
                          className={classNames(
                            item.current
                              ? "bg-gray-800 text-white"
                              : "text-gray-100 hover:text-white hover:bg-gray-600",
                            "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-gray-200"
                            aria-hidden="true"
                          />
                          <span
                            className={cx("truncate", {
                              hidden: collapsed,
                            })}
                          >
                            {item.name}
                          </span>
                        </a>
                      </Link>
                    </div>
                  ) : (
                    <Disclosure
                      as="div"
                      key={item.name}
                      className="space-y-1"
                      defaultOpen={item.current}
                    >
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={cx(
                              open
                                ? "bg-gray-800 text-white"
                                : "text-gray-100 hover:text-white hover:bg-gray-600",
                              "group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium focus:outline-none ",
                              {
                                "rounded-md": !collapsed,
                                "rounded-full w-10 h-10": collapsed,
                              }
                            )}
                            onClick={() => {
                              collapsed && setCollapsed(false);
                            }}
                          >
                            <item.icon
                              className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            {!collapsed && (
                              <span className="flex-1 truncate">
                                {item.name}
                              </span>
                            )}
                            <svg
                              className={cx(
                                open
                                  ? "text-gray-400 rotate-90"
                                  : "text-gray-300",
                                "ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400"
                              )}
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                            >
                              <path
                                d="M6 6L14 10L6 14V6Z"
                                fill="currentColor"
                              />
                            </svg>
                          </Disclosure.Button>

                          {!collapsed && (
                            <Disclosure.Panel className="space-y-1">
                              {item.children.map((subItem) => (
                                <Disclosure.Button
                                  key={subItem.name}
                                  as="a"
                                  href={subItem.href}
                                  title={subItem.title}
                                  className={cx(
                                    router.asPath.includes(subItem.href)
                                      ? "bg-gray-800 text-white"
                                      : "text-gray-100 hover:text-white hover:bg-gray-600",
                                    "group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-gray-100 hover:text-white hover:bg-gray-600"
                                  )}
                                >
                                  {subItem.icon && (
                                    <subItem.icon
                                      className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                  )}
                                  {subItem.name}
                                </Disclosure.Button>
                              ))}
                            </Disclosure.Panel>
                          )}
                        </>
                      )}
                    </Disclosure>
                  )
                )}
              </div>
              <div className="mt-6 pt-6">
                <div className="space-y-1 px-2">
                  {secondaryNavigation.map((item) => (
                    <Link key={item.name} href={item.href} shallow={true}>
                      <a
                        target={item.target ? item.target : ""}
                        className={cx({
                          "group flex items-center px-2 py-2 text-sm font-medium leading-6 text-gray-100 hover:bg-gray-600 hover:text-white": true,
                          "rounded-md ": !collapsed,
                          "rounded-full w-10 h-10": collapsed,
                        })}
                      >
                        <item.icon
                          className={cx("h-6 w-6 text-gray-200", {
                            "mr-4 ": !collapsed,
                          })}
                          aria-hidden="true"
                        />
                        {!collapsed && (
                          <span className="truncate">{item.name}</span>
                        )}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
            </nav>
          </div>
        </div>
        <footer>
          <Menu as="div" className="relative p-0 w-full">
            <div className="flex flex-row">
              <Menu.Button
                className="flex w-full items-center rounded-md px-2 py-2 pl-3 pr-6 text-sm font-medium text-gray-100 hover:text-white hover:bg-gray-600"
                onClick={() => collapsed && setCollapsed(!collapsed)}
              >
                <div className="flex flex-row">
                  <UserCircleIcon
                    className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  {!collapsed && (
                    <>
                      <span className="ml-3 text-sm font-medium text-white lg:block w-40 truncate">
                        <span className="sr-only">Open user menu for </span>
                        {AuthUser.email}
                      </span>
                      <ChevronUpIcon
                        className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
                        aria-hidden="true"
                      />
                    </>
                  )}
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="top-2 transform -translate-y-full absolute right-0 w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {/* <Menu.Items className="bottom-full absolute top-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"> */}
                <Menu.Item>
                  {({ active }) => (
                    <Link href={`/org/${orgId}/settings`} shallow={true}>
                      <a
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Profile
                      </a>
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link href={`/org/${orgId}/settings`} shallow={true}>
                      <a
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Settings
                      </a>
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link href="#" shallow={true}>
                      <a
                        onClick={() => {
                          signOut();
                        }}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Logout
                      </a>
                    </Link>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </footer>
      </div>

      <div
        className={cx(
          "flex flex-1 flex-col transition-all duration-300 ease-in-out",
          {
            "lg:pl-64": !collapsed,
            "lg:pl-24": collapsed,
          }
        )}
      >
        <div
          className={cx(
            "flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none",
            {
              // "lg:pl-64": !collapsed,
              "": !collapsed,
              "": collapsed,
            }
          )}
        >
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-400 focus:outline-none lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          {/* Search bar */}
          <div className="flex flex-1 justify-end px-4 sm:px-6 lg:max-w-6xl lg:px-8">
            <div className="flex flex-1">
              {orgId && <OrgSwitcher orgId={orgId} />}
            </div>
            {/* <div className="flex flex-1">
              <form className="flex w-full md:ml-0" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div
                    className="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                    aria-hidden="true"
                  >
                    <MagnifyingGlassIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="search-field"
                    name="search-field"
                    className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                    placeholder="Search transactions"
                    type="search"
                  />
                </div>
              </form>
            </div> */}

            <div className="ml-4 flex items-center md:ml-6 absolute top-4 right-8">
              {data?.subscription?.status &&
                !["active", "trialing"].includes(
                  data?.subscription?.status
                ) && (
                  <span className="text-red-600 text-sm">
                    Subscription is past due
                  </span>
                )}
              <div className="flex">
                {userHistoryEnabled && <RecentlyVisitedPages />}
                <Notification oid={orgId} />
              </div>
            </div>
          </div>
        </div>
        <main className="flex-1 pb-8">{children}</main>
      </div>
    </div>
  );
}

export default withUser({
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
  LoaderComponent: FullPageLoader,
})(LayoutAppAuthed);
