import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
const db = firebase.firestore();
// import firebase from "firebase/compat/app"

const handler = async (req, res) => {
  try {
    console.log("in notifications ");
    console.log(db);

    if (req.body.notification == "crealall") {
      const notificationDocsSnap = await db
        .collection("notifications")
        .where("orgId", "==", req.body.orgId)
        .get();
      console.log("first");
      await Promise.all(
        notificationDocsSnap.docs.map(async (notificationDoc) => {
          // const notificationData = notificationDoc.update({ dismissed: true }, { merge: true });
          db.collection("notifications").doc(notificationDoc.id).update({
            dismissed: true,
          });
        })
      );
    } else if (req.body.notification === "clearAllNotifications") {
      const notificationDocsSnap = await db
        .collection("notifications")
        .where("orgId", "==", req.body.orgId)
        .get();
      await Promise.all(
        notificationDocsSnap.docs.map(async (notificationDoc) => {
          db.collection("notifications").doc(notificationDoc.id).update({
            clear: true,
          });
        })
      );
    } else if (req.body.notification) {
      db.collection("notifications").doc(req.body.notification).update({
        dismissed: true,
      });
    }

    let notificationDocsSnap;
    if (req.body.all) {
      notificationDocsSnap = await db
        .collection("notifications")
        .where("orgId", "==", req.body.orgId)
        .orderBy("createdAt", "desc")
        .get();
    } else {
      notificationDocsSnap = await db
        .collection("notifications")
        .where("orgId", "==", req.body.orgId)
        .where("clear", "==", false)
        .orderBy("createdAt", "desc")
        .get();
    }

    var data = [];
    await Promise.all(
      notificationDocsSnap.docs.map(async (notificationDoc) => {
        let notificationId = notificationDoc.id;
        // console.log(notificationId);
        const notificationData = notificationDoc.data();
        data.push({ id: notificationId, ...notificationData });
      })
    );

    data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    return res.status(200).json({ notification: data });
  } catch (error) {
    console.log(error);
    return res.status(403).json({ error: error.message });
  }
};

export default handler;
